import $ from 'jquery';
import 'bootstrap';
import './assets/styles/main.scss';

const Headroom = require('headroom.js');
const header = document.querySelector('.site-header');
const headroom = new Headroom(header);
headroom.init();

// console.log(headroom);

// Components
// import Demo from './assets/scripts/components/demo';
// new Demo();

$(document).ready(() => {
	// console.log('jQuery ready');
});
